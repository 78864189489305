import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet de color negre lluent de fins a 2 cm d’altura, en forma d’espàtula formada per un peu i un cap (part fèrtil) en forma de llengua, quasi cilíndrica a vegades. El peu és cilíndric o un poc retorçat i pot mesurar fins a 4 cm. Les espores són allargades amb 7-8 compartiments transversals, de 65-80 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      